<template>
  <ion-page>
    <div class="background--login" />
    <ion-header class="ion-no-border">
      <ion-toolbar
        class="toolbar"
        color="transparent"
      >
        <ion-item
          slot="start"
          color="transparent"
          lines="none"
        >
          <ion-img
            class="web-logo"
            :src="webLogo"
          />
        </ion-item>
        <ion-item
          slot="end"
          color="transparent"
          class="text--white"
          lines="none"
        >
          <ion-label />
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div
        v-if="loading"
        class="mt-20"
      >
        <ion-progress-bar
          type="indeterminate"
        />
        <div class="mt-20">
          <ion-text style="font-size: 20px">
            Loading....
          </ion-text>
        </div>
      </div>
      <div v-else-if="noData">
        <ion-text
          class="text--white"
          style="font-size: 20px"
        >
          {{ $t('Invalid Token') }}
        </ion-text>
      </div>
      <div
        v-else
        class="container form--dark"
      >
        <div
          class="text-left"
          style="margin-bottom: 30px;"
        >
          <span class="text--white text-64">{{ $t('Complete') }} <span class="text--primary">{{ $t('Reset Password') }}</span></span>
        </div>
        <form
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              {{ $t('password') }}
            </ion-label>
            <ion-input
              id="username"
              v-model="password"
              enterkeyhint="done"
              autocomplete="password"
              :type="passwordInputModeVisible1 ? 'text' : 'password'"
              required
            />
            <div
              slot="end"
              @click="passwordInputModeVisible1 = !passwordInputModeVisible1"
            >
              <i
                class="mdi pwd-icon"
                :class="passwordInputModeVisible1 ? 'mdi-eye' : 'mdi-eye-off'"
              />
            </div>
          </ion-item>
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              {{ $t('Confirm New Password') }}
            </ion-label>
            <ion-input
              id="username"
              v-model="repeatPassword"
              enterkeyhint="done"
              autocomplete="password"
              :type="passwordInputModeVisible2 ? 'text' : 'password'"
              required
            />
            <div
              slot="end"
              @click="passwordInputModeVisible2 = !passwordInputModeVisible2"
            >
              <i
                class="mdi pwd-icon"
                :class="passwordInputModeVisible2 ? 'mdi-eye' : 'mdi-eye-off'"
              />
            </div>
          </ion-item>
          <div style="min-height: 30px;">
            <password-meter
              :password="password"
              @score="passwordScore = $event.score"
            />
            <transition
              name="fade1"
              mode="out-in"
            >
              <ion-label v-if="message.message">
                <p :class="message.class">
                  {{ message.message }}
                </p>
              </ion-label>
            </transition>
          </div>
          <ion-button
            lines="none"
            :disabled="!password || !repeatPassword || password !== repeatPassword"
            @click="resetPassword"
          >
            <ion-label>
              <ion-spinner
                v-if="loadingButton"
                color="dark"
                name="crescent"
              />
              <ion-label v-else>
                {{ $t('Reset Password') }}
              </ion-label>
            </ion-label>
          </ion-button>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonProgressBar } from '@ionic/vue'
import webLogo from '../assets/web/web-logo.png'
import PasswordMeter from 'vue-simple-password-meter'

export default {
  components: {
    IonProgressBar,
    PasswordMeter
  },
  data () {
    return {
      webLogo,
      loading: false,
      loadingButton: false,
      noData: false,
      passwordInputModeVisible1: false,
      passwordInputModeVisible2: false,
      selectedView: '1',
      message: '',
      token: '',
      password: '',
      repeatPassword: '',
      passwordScore: 0
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.getUsersData(this.$route.params)
  },
  methods: {
    getUsersData (token) {
      this.loading = true
      // console.log(token.token)
      this.$store.dispatch('completeResetPasswordGetData', token.token).then(response => {
        // console.log(response.data)
        const data = response.data
        if (data.response === 'Token OK') {
          this.token = token.token
          this.loading = false
        } else {
          this.loading = false
          this.noData = true
        }
      })
    },
    resetPassword () {
      this.loadingButton = true
      if (this.passwordScore <= 3) {
        this.message = { message: this.$t('Password is too weak'), class: 'error' }
        setTimeout(() => { this.message = {} }, 2000)
        this.loadingButton = false
      } else {
        this.$store.dispatch('completeResetPassword', { token: this.token, password: this.password }).then(response => {
          if (response) {
            this.$router.push('/login')
            this.loadingButton = false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ion-progress-bar {
    --background: gray;
}
.toolbar {
  color: white;
  .web-logo {
      max-width: 150px;
  }
}
    .text-64 {
        font-size: 30px;
    }
// .form--dark{
//     ion-item {
//         ion-label {
//             margin-top: 0!important;
//             margin-bottom: 5px!important;
//         }
//         .label {
//             font-size: 15px!important;
//         }
//     }
//     ion-col {
//         padding: 0;
//     }
// }

@media only screen and (min-width: 1000px) {
    .form--dark{
    .text-64 {
        font-size: 54px;
    }}
.container {
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
}
.background--login::after {
    background-size: 100% auto;
}
.toolbar {
  padding-left: 20vw;
  padding-right: 20vw;
}
}
.error {
  color: #eb445a;
}
.success {
  color: #ffd4a4;
}
.gray {
  color: rgb(70, 70, 70);
}
.fade1-enter-active, .fade1-leave-active {
  transition: opacity .2s;
}
.fade1-enter, .fade1-leave-to {
  opacity: 0;
}

</style>